import * as React from "react"
import styled from "@emotion/styled"

import Marquee from "react-fast-marquee"
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import "@fontsource/rubik-beastly"
import "@fontsource/flow-circular"
import "@fontsource/courier-prime/700.css"
import "@fontsource/open-sans/800.css"

import Image from "../images/boulangerie.jpg"
import { motion } from "framer-motion"

const Wrapper = styled.div`
.first {
  margin-top: 7.5vh;
  font-family: "Open Sans";
  font-weight: 800;
}
.block-xxx {
  font-family: "Flow Circular";
}
.block { 
  margin-top: 4vh;
}
.first .marquee {
  pointer-events: none;
  p {
    background-color: #666;
    color: #fff;
    padding: 1.25vh;
    font-size: .9125rem;
    letter-spacing: .5px;
    margin: 0;
  }
}
.block .marquee {
  p {
    background-color: #999;
    font-size: 1.66rem;
    padding: 2.5vh;
    font-family: "Rubik Beastly";
  }
}
.yellow .marquee {
  mix-blend-mode: multiply;
  p {
    background-color: yellow;
    color: #000;
    font-size: 1.25rem;
  }
}
.courier .marquee {
  p {
    font-family: 'Courier Prime', monospace;
    font-size: 1rem;
    background: #000;
  }
}
`
const Main = styled.main`
height: 90vh;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
figure {
  height: 90vh;
  width: 95vh;
  img {
    width: auto;
  }
}
`

const IndexPage = () => {
  return (
    <Wrapper>
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.5
        }}
    >
    <Marquee gradient={false} className="first"><p>he lay lifeless in the alley where we first caught eachother’s eye.</p></Marquee>
    <Marquee gradient={false} className="first block" direction="right" speed="10"><p>had he met his end a little earlier there would have been a selection of inconspicuous sized loaves.</p></Marquee>
    <Marquee gradient={false} delay="1" speed="80" className="first courier"><p>be on the lookout for female in her 20s with short auburn hair wear plaid calf-length dress. may now be in disquise.</p></Marquee>
    <Marquee gradient={false} delay="4" className="first yellow"><p>i forgot to remove the apron that had him splattered every which way.</p></Marquee>
    <Marquee gradient={false} delay="3" speed="60" className="first"><p>4 magnums. That balances a 2 meter loaf right?</p></Marquee>
    <Marquee gradient={false} delay="8" className="first block" direction="right" speed="40"><p>i’ll be a butcher making a delivery. why did i not have any meat?</p></Marquee>
    <Marquee gradient={false} delay="2" speed="30" className="first"><p>never make eye contact. no one will notice.</p></Marquee>
    <Main>
      <InnerImageZoom src={Image} zoomSrc={Image} hideHint={true} zoomType="hover" zoomScale="1.75" zoomPreload={true}/>
    </Main>
    </motion.div>
    </Wrapper>
  )
}

export default IndexPage
